import React, { useCallback, useState } from "react"
import {
  Anchor,
  Box,
  Button,
  Heading,
  Paragraph,
  Text,
  Form,
  FormField,
  TextArea,
  TextInput, MaskedInput, Spinner,
} from "grommet"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { MailOption, PhoneVertical, StatusGood } from "grommet-icons"
import { useFormik } from "formik"
import { phoneMask } from "../helpers/constants"
import * as Yup from "yup"
import Link from "../components/link"
import { generateLead } from "../helpers/analytics"

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Полето е задължително").max(255, "Моля въведете по-късо име"),
  email: Yup.string().email("Моля въведете валиден имейл").required("Полето е задължително"),
  phone_number: Yup.string(),
  body: Yup.string().required("Полето е задължително").max(4096, "Моля въведете по-късо съобщение"),
})

const ContactUsPage = () => {
  const [msgSent, setMsgSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, errors, handleChange, submitForm, status, handleBlur } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "+(359) ",
      body: "",
    },
    validationSchema,
    onSubmit: async (values, actions) => {
      actions.setStatus(null);
      setLoading(true);
      try {
        await fetch(`${process.env.GATSBY_AUTH_API_URL}/contact-us`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(values)
        })
        setMsgSent(true);
      } catch (e) {
        actions.setStatus('error');
      } finally {
        setLoading(false);
      }
    },
  })
  const handleBlurEvent = useCallback((e) => {handleBlur(e); generateLead('by_contact_form', values);}, [values, handleBlur]);
  return (
    <Layout>
      <Seo title="Contact us" />
      <Box align="center" pad="large">
        <Heading alignSelf="center">Връзка с нас</Heading>
        <Box direction="row-responsive" justify="evenly" fill margin={{ top: "medium" }}>

          <Box gap="small" align="center">
            <Heading level={3} size="small">Имате въпрос и искате да се обърнете към нас?</Heading>
            <Box direction="row" gap="xxsmall" align="center"><Text>Пишете ни на</Text> <MailOption size="medium"
                                                                                                    color="black" />
              <Anchor onClick={() => generateLead('by_email')}
                href="mailto:info@mitabits.com" label="info@mitabits.com" size="large" /></Box>
            <Text>или попълнете формата по-долу.</Text>
            <Paragraph>обикновено отговаряме до няколко часа.</Paragraph>
          </Box>
          <Box gap="small" align="center">
            <Heading level={3} size="small">Искате отговор на момента?</Heading>
            <Box direction="row" gap="xxsmall" align="center"><Text>Обадете ни се веднага на</Text><PhoneVertical
              size="medium" color="black" /> <Anchor onClick={() => generateLead('by_phone_number')} href="tel:+359879650422" label="0879650422" size="large" /></Box>
          </Box>
        </Box>
        {msgSent ? <Box pad="large" align="center">
          <Box direction="row" gap="small" align='center'>
            <StatusGood size="xlarge" color="status-ok" /><Heading level={2}>Вашето съобщение е изпратено успешно!</Heading>
          </Box>
          <Paragraph>
            докато чакате отговор, може да се регистрирате в <Link to={process.env.GATSBY_DASHBOARD_URL}>платформата за управление</Link> на <b>mitabits</b>.
            там има подробна информация за начина на инсталиране и конфигуриране на mi<b>Bus</b>.
          </Paragraph></Box> :
        <Box width="large">
          <Heading level={3} alignSelf="center">Оставете ни съобщение</Heading>
          <Form>
            <FormField label="Име" error={errors.name}>
              <TextInput
                name="name"
                onBlur={handleBlurEvent}
                value={values.name}
                onChange={handleChange}
              />
            </FormField>
            <FormField label="Email" error={errors.email}>
              <TextInput
                name="email"
                onBlur={handleBlurEvent}
                value={values.email}
                onChange={handleChange}
              />
            </FormField>
            <FormField label="Телефон" error={errors.phone_number}>
              <MaskedInput
                mask={phoneMask}
                name="phone_number"
                onBlur={handleBlurEvent}
                value={values.phone_number}
                onChange={handleChange}
              />
            </FormField>
            <FormField label="Съобщение" error={errors.body}>
              <TextArea
                name="body"
                onBlur={handleBlurEvent}
                value={values.body}
                onChange={handleChange}
              />
            </FormField>
            {loading ? <Box align='center'><Spinner/></Box> : <Button type="submit" primary label="Изпрати" onClick={submitForm} />}
            {status && <Box align='center' fill pad='medium'>
              <Text color='status-error' weight='bold'>Нещо се обърка</Text>
              <Paragraph>Моля свържете се с нас с някой от другите изброени по-горе методи.</Paragraph>
            </Box>}
          </Form>
        </Box>}
      </Box>
    </Layout>
  )
}

export default ContactUsPage
